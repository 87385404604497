import { useAuth } from '../../hooks/Auth';
import './Header.css';

import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon, PowerIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';
import { ReactComponent as UserLogedSVG } from '../../assets/user-loged.svg';
import { ReactComponent as UserLoginSVG } from '../../assets/user-login.svg';

function Header(_props: any) {
  const { user, signOut } = useAuth();
  return (
    <div className="headerContainer">
      <div>
        <img
          className="rounded-full logoContainer"
          src="/small-logo.png"
          alt="usd.uy.logo"
        />
      </div>
      {user ? (
        <Popover className="relative mt-5 mr-5">
          <Popover.Button className="inline-flex items-center gap-x-1p-5 leading-6 text-white uppercase">
            <UserLogedSVG className="text-white w-10 inline" />
            {user.user_metadata.full_name}
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 left-auto ">
              <div className="w-56 shrink rounded-xl bg-dollar-beige text-sm font-semibold leading-6 text-gray-900 shadow-lg text-red-700 ">
                <button
                  key={'salir'}
                  className="block p-2 hover:underline w-full text-left"
                  onClick={() => signOut()}
                >
                  <PowerIcon
                    className="inline mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  SALIR
                </button>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      ) : (
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-semibold uppercase rounded"
          onClick={() => {
            window.location.href = '/login';
          }}
        >
          <UserLoginSVG className="text-white w-9 inline-block" />
          Ingresar
        </button>
      )}
    </div>
  );
}

export default Header;
