import { useAuth } from '../../hooks/Auth';
import { IColumn } from '../../models/interfaces';
import { MyQuote } from '../../models/models';

interface IProps {
  data: MyQuote[];
  properties: IColumn[];
  loading: boolean;
  setLimit: (limit: number) => void;
}

const renderFieldLoading = (amount: number) => {
  return [...Array(amount)].map((_, index) => (
    <tr key={index}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-black sm:pl-6">
        <div className="h-2.5 bg-gray rounded-full w-22 mb-2.5"></div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-black">
        <div className="h-2.5 bg-gray rounded-full w-22 mb-2.5"></div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-black">
        <div className="h-2.5 bg-gray rounded-full w-22 mb-2.5"></div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-black">
        <div className="h-2.5 bg-gray rounded-full w-22 mb-2.5"></div>
      </td>
      <td className="w-16 whitespace-nowrap px-3 py-4 text-sm text-black">
        <div className="h-2.5 bg-gray rounded-full w-16 mb-2.5"></div>
      </td>
    </tr>
  ));
};

export default function Table(props: IProps) {
  const { data, properties, loading, setLimit } = props;
  const { isSignedIn } = useAuth();
  console.log(properties);

  const limit = isSignedIn ? data.length : 5;

  const visibleProperties = properties.filter((property) => property.show);

  return (
    <div className="mt-8 table-container w-full max-w-[750px] mx-auto">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
          {isSignedIn ? (
            <p className="font-bold uppercase text-end">
              {data.length} resultados
            </p>
          ) : (
            <p className="font-bold uppercase text-end">Últimos 5 resultados</p>
          )}
          <div className="overflow-hidden shadow ring-2 ring-dollar-opac-green ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-dollar-opac-green bg-white">
              <thead className="bg-gray text-black">
                <tr>
                  {visibleProperties.map((property: IColumn) => (
                    <th
                      key={property.key}
                      scope="col"
                      className="px-3 py-3 text-md font-medium uppercase tracking-wider"
                    >
                      {property.icon && property.icon}
                      {property.value}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-dark-gray bg-white">
                {loading && renderFieldLoading(1)}
                {renderData(data, limit, visibleProperties)}
              </tbody>
            </table>
          </div>
          {isSignedIn && renderPagination(setLimit)}
        </div>
      </div>
    </div>
  );
}

const limits = [10, 50, 100];

const renderPagination = (setLimit: (limit: number) => void) => {
  return (
    <div className="mb-4 text-right min-w-full mt-2 sm:rounded-lg border-none">
      <label htmlFor="rows-per-page" className="mr-2">
        Filas por página:
      </label>
      <select
        id="rows-per-page"
        name="rows-per-page"
        className="px-3 py-2 sm:rounded-md shadow bg-white text-sm focus:outline-none focus:ring focus:border-blue-500"
        onChange={(event) => setLimit(parseInt(event.target.value))}
      >
        {limits.map((limit) => (
          <option key={limit} value={limit}>
            {limit}
          </option>
        ))}
        <option value={1000}>Todos</option>
      </select>
    </div>
  );
};

const renderData = (data: MyQuote[], limit: number, properties: IColumn[]) => {
  if (data.length === 0) {
    return (
      <tr>
        <td
          className="whitespace-nowrap py-4 pl-4 pr-3 text-md font-medium text-black sm:pl-6 uppercase"
          colSpan={properties.length}
        >
          No hay resultados
        </td>
      </tr>
    );
  } else {
    const showTime = properties.find(
      (property) => property.key === 'created_at'
    );

    return data.slice(0, limit).map((quote, index) => (
      <tr key={index}>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-md font-medium text-black sm:pl-6 uppercase">
          {quote.operation === 'buy' ? 'Compra' : 'Venta'}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-md text-black">
          {quote.option1} {quote.amount}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-md text-black">
          {quote.currentQuote}
        </td>
        <td className="whitespace-nowrap px-3 py-4 uppercase text-md text-black">
          {quote.source}
        </td>
        {showTime && (
          <td className="whitespace-nowrap px-3 py-4 uppercase text-md text-black">
            {formatDate(quote.created_at)}
          </td>
        )}
        <td className="whitespace-nowrap px-3 py-4 text-md text-black">
          {quote.option2} {quote.total}
        </td>
      </tr>
    ));
  }
};

const formatDate = (date: string) => {
  const dateObject = new Date(date);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();

  return `${day}/${month}/${year}`;
};
