import './App.css';
import Home from './pages/Home';
import { AuthProvider } from './hooks/Auth';
import Login from './pages/Login';
import { ProtectedRoute } from './pages/ProtectedRoute';
import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

const ProtectedRoutes = () => (
  <ProtectedRoute>
    {' '}
    <Login />
  </ProtectedRoute>
);

function App() {
  return (
    <React.StrictMode>
      <AuthProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/login" component={ProtectedRoutes} />
          </Switch>
        </BrowserRouter>
        {/* <SpeedInsighs /> */}
      </AuthProvider>
    </React.StrictMode>
  );
}

export default App;
