import './Input.css'

function Input(props: any) {
  return (
    <input
      id='custom-input'
      {...props}
      className="
      sm:rounded-lg bg-gray-50 sm:text-md focus:ring-blue-50 block w-full p-3 rounded-md border-0 py-1.5 text-black sm:text-sm sm:font-medium shadow-sm ring-dollar-opac-green placeholder:text-black border-dollar-darkgray" />

  );
}

export default Input;
