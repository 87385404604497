import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'

export default function TermAndConditions({ open, setOpen }: { open: boolean, setOpen: any }) {

    return (
        <Transition.Root show={open} as={Fragment} >
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <div className="fixed text-center inset-0 z-10 overflow-y-auto">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel className="m-auto relative transform overflow-x-hidden overflow-scroll rounded-lg bg-white px-4 text-left shadow-xl transition-all sm:my-8 md:w-2/4 sm:w-full  max-h-full">
                            <div className="relative p-2 text-right">
                                <XMarkIcon className="h-6 w-6 text-gray-500 absolute right-0 cursor-pointer" onClick={() => setOpen(false)} />
                            </div>
                            <div className='p-8'>
                                <p className='text-3xl text-center'>Descargo de Responsabilidad para Comparación de Cotizaciones de Divisas</p>
                                <p className='mt-1'>Bienvenido a nuestro sitio web de comparación de cotizaciones entre USD (dólares estadounidenses) y UYU (pesos uruguayos) ofrecidas por diferentes casas de cambio y bancos en Uruguay. Queremos informarte sobre la naturaleza de nuestro servicio y establecer algunas consideraciones importantes antes de utilizar la información proporcionada en este sitio.</p>
                                <ol>
                                    <li className='mt-1'>
                                        <strong>Propósito Informativo:</strong> Este sitio tiene como único propósito proporcionar información sobre las cotizaciones de divisas ofrecidas por diversas entidades financieras en Uruguay. La información aquí presentada se ofrece con fines puramente informativos y no constituye asesoramiento financiero.
                                    </li>
                                    <li className='mt-1'>
                                        <strong>Fluctuaciones del Mercado:</strong> Las tasas de cambio de divisas están sujetas a fluctuaciones constantes debido a factores económicos y políticos. La información proporcionada en este sitio se basa en datos recopilados en un momento específico y puede cambiar sin previo aviso. No garantizamos la precisión ni la actualización inmediata de las tasas de cambio.
                                    </li>
                                    <li className='mt-1'>
                                        <strong>Responsabilidad del Usuario:</strong> Los usuarios son plenamente responsables de verificar la vigencia y exactitud de las cotizaciones antes de tomar decisiones financieras. Recomendamos encarecidamente que los visitantes confirmen las tasas directamente con la entidad financiera correspondiente antes de realizar transacciones.
                                    </li>
                                    <li className='mt-1'>
                                        <strong>Cambios en las Condiciones:</strong> Las políticas y condiciones de las casas de cambio y bancos pueden cambiar sin previo aviso. No somos responsables de las modificaciones en las tarifas, comisiones u otras condiciones ofrecidas por las entidades financieras enumeradas en este sitio.
                                    </li>
                                    <li className='mt-1'>
                                        <strong>Uso Consciente:</strong> Al utilizar este sitio, los usuarios aceptan que la información se proporciona "tal cual" y bajo su propio riesgo. No nos hacemos responsables de pérdidas o inconvenientes derivados del uso de la información proporcionada en este sitio.
                                    </li>
                                </ol>
                                <br />
                                <p>Este descargo de responsabilidad está sujeto a cambios y actualizaciones sin previo aviso. Te recomendamos revisar regularmente esta página para conocer cualquier modificación.</p>
                                <br />
                                <p>Gracias por utilizar nuestro servicio de comparación de cotizaciones de divisas. Si tienes alguna pregunta o inquietud, no dudes en ponerte en contacto con nosotros.</p>
                                <br />
                                <p>Atentamente, USD.UY</p>

                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
