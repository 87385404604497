import { useEffect, useState } from "react";

interface IBanner {
    title: string;
    description: string;
    image: any;
    link: string;

}
export const useBanner = () => {
    const [banner, setBanner] = useState<IBanner | null>({} as IBanner);
    const dataEnv = process.env.REACT_APP_PUBLICITY_API;
    const isBannerOff = dataEnv === 'false'; 
    useEffect(() => {
        if (isBannerOff) {
            setBanner(null);
        } else {
            const bannerenv = process.env.REACT_APP_PUBLICITY_API?.split('|');
            const title = bannerenv?.[0] || '';
            const description = bannerenv?.[1] || '';
            const image = bannerenv?.[2] || '';
            const link = bannerenv?.[3] || '';
            setBanner({ title, description, image, link })
        }
    }, []);

    return [banner]
}