import { Redirect } from "react-router-dom";
import { useAuth } from "../hooks/Auth";

export const ProtectedRoute = ({ children, }: any) => {
    const { user } = useAuth();
    if (user) {
        return <Redirect to='/home' />
    }

    return <>{children}</>
}