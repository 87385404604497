import { createClient } from "@supabase/supabase-js";

const projectURL = process.env.REACT_APP_SUPABASE_URL || ''
//  'https://ajvvkyvazgfjmlorfdzm.supabase.co';
const projectKey = process.env.REACT_APP_SUPABASE_KEY || ''
//  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFqdnZreXZhemdmam1sb3JmZHptIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTk4NzYzMjUsImV4cCI6MjAxNTQ1MjMyNX0.OWgvcQ51dzK9ZqXqPdC85_7iSgj2BjZz_Nqzr8phSM0';

console.log('projectURL', projectURL);
console.log('projectKey', projectKey);


export const supabase = createClient(projectURL, projectKey);