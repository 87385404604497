// custom hook to compare

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserInfo, MyQuote } from '../models/models';
import { ISource } from '../store/features/sourcesSlice';
import { RootState } from '../store/store';
import { supabase } from '../supabase';
import { useAuth } from './Auth';
import { getBrowserInfo } from './useBrowserInfo';
import { Iitem } from './useList';
import { calculateTotal, getTypeOperation, validateSource } from './utils';

export interface ICalculate {
  amount: number;
  option1: string;
  option2: string;
  source: Iitem | null;
  limit: number;
  // operation: any | null;
}

function useCalculate(value: ICalculate) {
  const [results, setResults] = useState<MyQuote[]>([]);
  const [loading, setLoading] = useState(false);
  const sourceData: any = useSelector(
    (state: RootState) => state.sources.sources
  );
  const { isSignedIn, user } = useAuth();

  // const { geolocation } = useLocation()

  const onCalculate = () => {
    try {
      const browserInfo: BrowserInfo = getBrowserInfo();
      setLoading(true);
      const sourceObj = sourceData.find(
        (item: ISource) => item.source === value.source?.name
      );
      console.log('SourceObj: ', sourceObj);
      validateSource(sourceObj);

      const operation = getTypeOperation(value.option1, value.option2);

      const total = calculateTotal(value.amount, sourceObj, operation);
      const data: MyQuote = {
        amount: value.amount,
        option1: value.option1,
        option2: value.option2,
        source: value.source?.name || '',
        sourceObj,
        browserInfo,
        geolocation: null,
        operation: operation,
        total: Number(total.toFixed(3)),
        currentQuote: operation === 'buy' ? sourceObj.buy : sourceObj.sell,
        created_at: new Date().toDateString(),
      };
      setTimeout(() => {
        setLoading(false);
        saveQuote(data);
      }, 2000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadQuotes(value.limit);
  }, []);

  useEffect(() => {
    loadQuotes(value.limit);
  }, [value.limit]);

  const loadQuotes = (limit: number) => {
    if (isSignedIn) {
      // get data from supabase and user column id
      supabase
        .from('quotes')
        .select('*')
        .eq('user', user?.id)
        .order('created_at', { ascending: false })
        .limit(limit)
        .then((response: any) => {
          const data = response.data;
          // const firstThree = data.slice(0, 3);
          setResults(data);
        });
    } else {
      const oldQuotes = JSON.parse(localStorage.getItem('Quotes') || '[]');
      if (oldQuotes) {
        const orderByDateDESC = oldQuotes.sort(
          (a: any, b: any) => b.created_at - a.created_at
        );
        // get first three element
        const firstThree = orderByDateDESC.slice(0, 5);
        setResults(firstThree);
      }
    }
  };

  const saveQuote = (data: MyQuote) => {
    const newArray = [{ ...data }, ...results];
    saveInLocalStorage({ ...data });
    saveInSupabase({ ...data });
    setResults(newArray);
  };

  function saveInLocalStorage(data: MyQuote) {
    const oldQuotes = localStorage.getItem('Quotes');
    if (oldQuotes) {
      const newQuotes = [data, ...JSON.parse(oldQuotes)];
      localStorage.setItem('Quotes', JSON.stringify(newQuotes));
    } else {
      localStorage.setItem('Quotes', JSON.stringify([data]));
    }
  }

  function saveInSupabase(data: MyQuote) {
    supabase
      .from('browserInfos')
      .insert([{ ...data.browserInfo }])
      .select()
      .then((response: any) => {
        const browserInfoId = response.data[0].id;
        supabase
          .from('geolocations')
          .insert([{ ...data.geolocation }])
          .select()
          .then((response: any) => {
            const geolocationId = response.data[0].id;
            supabase
              .from('quotes')
              .insert([
                {
                  amount: data.amount,
                  option1: data.option1,
                  option2: data.option2,
                  source: data.source,
                  sourceObj: data.sourceObj,
                  browserInfo: browserInfoId,
                  geolocation: geolocationId,
                  operation: data.operation,
                  total: data.total.toFixed(3),
                  currentQuote: data.currentQuote,
                },
              ])
              .then((response) => {
                console.log('Response from supabase: ', response);
              });
          });
      });
  }

  return { results, loading, onCalculate };
}

export { useCalculate };
