import { Operation } from "../models/models";

const getTypeOperation = (option1: string, option2: string): Operation => {
    if (option1 === 'USD' && option2 === 'UYU') {
        return Operation.SELL;
    } else if (option1 === 'UYU' && option2 === 'USD') {
        return Operation.BUY;
    }
    // Si ninguna de las condiciones anteriores se cumple
    return Operation.BUY;
}

const calculateTotal = (amount: number, sourceObj: any, operation: Operation) => {
    // si es venta(USD->UYU) se multiplica por el valor de venta
    // si es compra(UYU->USD) se divide por el valor de compra
    if (operation === Operation.SELL) {
        return amount * sourceObj.sell;
    } else if (operation === Operation.BUY) {
        return amount / sourceObj.buy;
    }
    return 0;
}

const validateSource = (sourceObj: any) => {
    if (!sourceObj) {
        throw new Error('No se encontró la fuente de datos');
    }
    if (!sourceObj.buy || !sourceObj.sell) {
        throw new Error('No se encontró el valor de compra o venta');
    }
}


export { getTypeOperation, calculateTotal, validateSource }