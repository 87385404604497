export default function Footer() {
  return (
    <footer className="pt-6 text-black bg-dollar-beige text-dollar-darkgray">
        <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="px-2">
                    <h2 className="text-xl font-bold mb-4">CONTACTO</h2>
                    <p>¿Tienes alguna pregunta o sugerencia? <br/> ¡Nos encantaría saber de ti!</p>
                    <p className="mt-4">Para Usuarios: Si tienes consultas sobre las cotizaciones o sugerencias para mejorar nuestro servicio, contáctanos en <a href="mailto:hola@usd.uy" className="text-sky underline">hola@usd.uy</a></p>
                    <p className="mt-2">Para Instituciones Financieras: Si representas a una institución financiera y deseas colaborar con nosotros, por favor contáctanos en <a href="mailto:mariano.bordon@usd.uy" className="text-sky underline">mariano.bordon@usd.uy</a></p>
                </div>
                <div className="px-2">
                    <h2 className="text-xl font-semibold mb-4">Descargo de Responsabilidad: Cotizaciones de Divisas</h2>
                    <p>La información sobre cotizaciones de cambio es solo referencial. Las mismas pueden cambiar; verifica directamente con las instituciones financieras antes de realizar transacciones. Los usuarios asumen la responsabilidad de sus decisiones financieras.</p>
                </div>
            </div>
        </div>
        <div className="p-4 mt-2 bg-dollar-darkgray text-white">
                USD.UY - Cotizaciones de cambio de USD a UYU en tiempo real
            </div>
    </footer>
  )
}
