import { useState, useEffect } from 'react';
import Input from '../components/Input/Input';
import { ICalculate, useCalculate } from '../hooks/useQuote';
import { useLists } from '../hooks/useList';
import Table from '../components/Table/Table';
import TermAndConditions from '../components/TermAndConditions';
import './Home.css';
import Layout from '../components/Layout';
import { useAuth } from '../hooks/Auth';
import Banner from '../components/Banner/Banner';
import InputSelect from '../components/InputSelect/InputSelect';
import Cards from '../components/Cards/Cards';
import { IColumn } from '../models/interfaces';
import { ReactComponent as QuoteSVG } from '../assets/quote.svg';
import SourceSVGComponent from '../assets/source';
import OperationSVGComponent from '../assets/operation';
import { ReactComponent as CalendarSVG } from '../assets/calendar.svg';

const values = [
  { key: '1', value: 'USD' },
  { key: '2', value: 'UYU' },
];

interface Iitem {
  key: string;
  value: string;
}
function Home() {
  const { list } = useLists();
  const [terms, setTerms] = useState(false);
  const { isSignedIn } = useAuth();
  const [currentData, setcurrentData] = useState<ICalculate>({
    option1: 'USD',
    option2: 'UYU',
    amount: 0,
    source: list[0],
    limit: 10,
    // operation: { key: 'buy', value: 'Compra' },
  });
  const { results, loading, onCalculate } = useCalculate(currentData);

  useEffect(() => {
    if (list) {
      setcurrentData({ ...currentData, source: list[0] });
    }
  }, [list]);

  const customList = list.map((item: any) => {
    const data: Iitem = { key: item.name, value: item.name };
    return data;
  });

  const setLimit = (value: number) => {
    console.log(value);
    if (isNaN(value)) {
      setcurrentData({ ...currentData, limit: 999999 });
    } else {
      setcurrentData({ ...currentData, limit: value });
    }
  };

  const handleSelectOption = (value: string, field: string) => {
    const choices = ['USD', 'UYU'];
    let currentDatatoUpload = { ...currentData };

    if (field === 'option1') {
      currentDatatoUpload = { ...currentData, [field]: value };
      if (currentData.option2 === value) {
        const option = choices.filter((item) => item !== value);
        currentDatatoUpload.option2 = option[0];
        setcurrentData(currentDatatoUpload);
      }
    } else {
      currentDatatoUpload = { ...currentData, [field]: value };
      if (currentData.option1 === value) {
        const option = choices.filter((item) => item !== value);
        currentDatatoUpload.option1 = option[0];
        setcurrentData(currentDatatoUpload);
      }
    }
  };

  const handleChange = (value: any, field: string) => {
    setcurrentData({ ...currentData, [field]: value });
  };

  const onClickQuotize = (event: any) => {
    event.preventDefault();
    onCalculate();
  };

  const termAndConditions = () => {
    setTerms(!terms);
  };

  const toggleOptions = () => {
    const option1 = currentData.option1;
    const option2 = currentData.option2;
    setcurrentData({ ...currentData, option1: option2, option2: option1 });
  };

  const getColumnNames = (): IColumn[] => {
    return [
      {
        key: 'operation', value: 'Operación', show: true,
        icon: <OperationSVGComponent
          className={`w-10 h-10 inline mx-1 ${loading && 'animate-spin	'
            }`}
        />
      },
      { key: 'option1', value: 'Moneda', show: true },
      {
        key: 'currentQuote',
        value: 'Cotización',
        icon: <QuoteSVG className="w-8 h-8 inline mx-1" />,
        show: true,
      },
      {
        key: 'source',
        value: 'Fuente',
        icon: <SourceSVGComponent className="w-8 h-8 inline mx-1" />,
        show: true,
      },
      {
        key: 'created_at',
        value: 'Fecha',
        show: isSignedIn,
        icon: <CalendarSVG className="w-8 h-8 inline mx-1" />,
      },
      { key: 'option2', value: 'Total', show: true },
    ].filter((item) => item);
  };
  return (
    <Layout>
      <main className="align-bottom mainContainer">
        <div className="z-10 max-w-2 items-center justify-between font-mono text-sm mainDiv">
          <section className="flex flex-col items-center justify-center logoSection">
            <img
              className="w-[250px] rounded-full logoSection"
              src="/image0.png"
              alt="usd.uy.logo"
            />
          </section>
          <section className="relative px-3 pb-1.5 pt-2 ring-gray-300 formSection">
            <form onSubmit={onClickQuotize}>
              <label className="text-sm font-medium text-left labelInput">
                Importe
              </label>
              <Input
                type="number"
                label="Importe"
                placeholder="1400"
                placeholderTextColor=""
                required
                onChange={(event: any) =>
                  handleChange(event.target.value, 'amount')
                }
              />
              <div className="my-1 flex items-center space-x-4 justify-center">
                <div className="w-full m-0">
                  <label className="text-sm font-medium text-left labelInput">
                    Tengo
                  </label>
                  <InputSelect
                    items={values}
                    selected={values.find(
                      (item) => item.value === currentData.option1
                    )}
                    onChange={(value: string) => {
                      handleSelectOption(value, 'option1');
                    }}
                  />
                </div>
                <span
                  className=" text-dollar-darkgray cursor-pointer hover:text-dark-gray"
                  onClick={toggleOptions}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 mt-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                    />
                  </svg>
                </span>

                <div className="w-full m-0">
                  <label className="text-sm font-medium text-left labelInput">
                    Necesito
                  </label>
                  <InputSelect
                    items={values}
                    selected={values.find(
                      (item) => item.value === currentData.option2
                    )}
                    onChange={(value: string) => {
                      handleSelectOption(value, 'option2');
                    }}
                  />
                </div>
              </div>

              {currentData.source && (
                <>
                  <label className="block text-sm font-medium text-gray-700 text-left">
                    Fuente
                  </label>
                  <InputSelect
                    selected={{
                      value: currentData.source ? currentData.source?.name : '',
                    }}
                    items={customList}
                    onChange={(value: string) =>
                      handleChange(
                        list.find((item) => item.name === value),
                        'source'
                      )
                    }
                  />
                </>
              )}
              <button
                disabled={loading}
                type="submit"
                className="w-full mt-2 rounded-md bg-white px-3.5  text-sm font-semibold text-black uppercase shadow-sm ring-1 ring-dollar hover:bg-gray"
              >
                <OperationSVGComponent
                  className={`w-10 h-10 inline mx-1 ${loading && 'animate-spin	'
                    }`}
                />
                {loading ? 'Cargando...' : 'Cotizar'}
              </button>
            </form>
          </section>
          {!isSignedIn && (
            <div className="text-suggest my-9 p-4 bg-green-light rounded-lg sm:w-full md:w-96 m-auto ">
              <p className="">
                Crea tu usuario para acceder a mas opciones, futuras funciones
                exclusivas y recibir actualizaciones especiales
              </p>
            </div>
          )}
        </div>
        <Table
          loading={loading}
          data={results}
          properties={getColumnNames()}
          setLimit={setLimit}
        />
        <Cards
          data={results}
          properties={getColumnNames()}
          loading={loading}
          setLimit={setLimit}
        />
        <a
          className="z-10 mt-6 font-bold text-dollar-darkgray cursor-pointer"
          onClick={termAndConditions}
        >
          Términos y condiciones
        </a>
        <TermAndConditions open={terms} setOpen={termAndConditions} />
        <Banner />
      </main>
    </Layout>
  );
}

export default Home;
