function getBrowserInfo() {
    return {
        userAgent: navigator.userAgent,
        appName: navigator.appName,
        appVersion: navigator.appVersion,
        platform: navigator.platform,
        language: navigator.language,
    };
}

export { getBrowserInfo };
