function useProviders() {
  const googleProvider = process.env.REACT_APP_GOOGLE_PROVIDER;
  const appleProvider = process.env.REACT_APP_APPLE_PROVIDER;
  const facebookProvider = process.env.REACT_APP_FACEBOOK_PROVIDER;

  const isGoogleProvider = () => googleProvider === 'true';
  const isFacebookProvider = () => facebookProvider === 'true';
  const isAppleProvider = () => appleProvider === 'true';

  const useGoogleProvider = () => (isGoogleProvider() ? true : false);
  const useFacebookProvider = () => (isFacebookProvider() ? true : false);
  const useAppleProvider = () => (isAppleProvider() ? true : false);

  return [useGoogleProvider, useFacebookProvider, useAppleProvider];
}

export default useProviders;
