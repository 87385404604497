import { useAuth } from '../../hooks/Auth';
import { IColumn } from '../../models/interfaces';
import { MyQuote } from '../../models/models';
import './Cards.css';
import { ReactComponent as CalendarSVG } from '../../assets/calendar.svg';
import SourceSVGComponent from '../../assets/source';

interface IProps {
  data: MyQuote[];
  properties: IColumn[];
  loading: boolean;
  setLimit: (limit: number) => void;
}

const renderFieldLoading = (amount: number) => {
  return [...Array(amount)].map((_, index) => <CardSkeleton key={index} />);
};

const parseTypeOfMoney = (type: string) => {
  if (type === 'UYU') {
    return '$';
  } else {
    return 'U$S';
  }
};

function CardSkeleton() {
  return (
    <div className="animate-pulse bg-white rounded-lg p-4 shadow-md w-full h-56">
      <div className="flex justify-between items-center mb-4">
        <div className="h-4 bg-gray-300 rounded-full w-1/2"></div>
        <div className="h-3 bg-gray-300 rounded w-1/4"></div>
      </div>
      <div className="flex justify-between items-center font-bold">
        <div className="h-6 bg-gray-300 rounded w-1/2"></div>
        <div className="text-right">
          <div className="h-3 bg-gray-300 rounded w-20 mb-1"></div>
          <div className="h-3 bg-gray-300 rounded w-10"></div>
        </div>
      </div>
    </div>
  );
}

function Cards(props: IProps) {
  const { data, properties, loading, setLimit } = props;
  const { isSignedIn } = useAuth();
  console.log(properties);

  const limit = isSignedIn ? data.length : 3;

  return (
    <div className=" card-container mt-8 w-full max-w-[750px] mx-auto">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
          {isSignedIn ? (
            <p className="font-bold uppercase text-end">
              {data.length} resultados
            </p>
          ) : (
            <p className="font-bold uppercase text-end">Últimos 5 resultados</p>
          )}
          <div className="flex flex-col overflow-hidden shadow ring-2 ring-dollar-opac-green ring-opacity-5 sm:rounded-lg justify-center">
            {loading && renderFieldLoading(1)}
            {renderData(data, limit, properties, isSignedIn)}
          </div>
          {isSignedIn && renderPagination(setLimit)}
        </div>
      </div>
    </div>
  );
}

const limits = [10, 50, 100];

const renderPagination = (setLimit: (limit: number) => void) => {
  return (
    <div className="mb-4 text-right min-w-full mt-2 sm:rounded-lg border-none">
      <label htmlFor="rows-per-page" className="mr-2 text-black">
        Filas por página:
      </label>
      <select
        id="rows-per-page"
        name="rows-per-page"
        className="px-3 py-2 sm:rounded-md shadow bg-white text-sm focus:outline-none focus:ring focus:border-blue-500"
        onChange={(event) => setLimit(parseInt(event.target.value))}
      >
        {limits.map((limit) => (
          <option key={limit} value={limit}>
            {limit}
          </option>
        ))}
        <option value={1000}>Todos</option>
      </select>
    </div>
  );
};

const renderData = (
  data: MyQuote[],
  limit: number,
  properties: IColumn[],
  isSignedIn: boolean
) => {
  if (data.length === 0) {
    return (
      <tr>
        <td
          className="whitespace-nowrap py-4 pl-4 pr-3 text-md font-medium text-black sm:pl-6 uppercase"
          colSpan={properties.length}
        >
          No hay resultados
        </td>
      </tr>
    );
  } else {
    return data.slice(0, limit).map((quote, _index) => (
      <div className="card-container m-2 bg-white rounded-lg p-4 shadow-md w-full">
        <div className="mb-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-8 h-8 text-green-500 inline-block mr-1"
          >
            <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z" />
            <path
              fillRule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z"
              clipRule="evenodd"
            />
          </svg>
          <span className="py-1 px-3 font-dark-gray rounded-full text-xl">
            {quote.operation === 'buy' ? 'Compra' : 'Venta'}
          </span>
          <span className="text-green-500 text-xl font-bold">
            {parseTypeOfMoney(quote.option1)} {quote.amount}
          </span>
        </div>
        <div className="text-gray-600 mb-2 text-md">Recibirás:</div>
        <div className="amount-container">
          {parseTypeOfMoney(quote.option2)} {quote.total}
        </div>
        <div className="mb-2">
          <SourceSVGComponent className="w-8 h-8 text-green-500 inline-block mr-1" />
          <span className="py-1 px-3 font-dark-gray rounded-full text-xl">
            {quote.source}
          </span>
          <span className="text-green-500 text-xl font-bold">
            ${quote.currentQuote}
          </span>
        </div>
        {isSignedIn && (
          <div className="mb-2">
            <span className="text-gray-600 text-md">
              <CalendarSVG />
            </span>
            <span className="py-1 px-3 font-dark-gray rounded-full text-xl">
              {formatDate(quote.created_at)}
            </span>
          </div>
        )}
      </div>
    ));
  }
};

const formatDate = (date: string) => {
  const dateObject = new Date(date);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();

  return `${day}/${month}/${year}`;
};

export default Cards;
