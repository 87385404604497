import { useBanner } from '../../hooks/useBanner'
import './Banner.css'
function Banner() {
    const [banner] = useBanner()
    console.log(banner);
    if (banner === null) return (<></>)
    return (
        <div className='w-full max-w-[750px] mx-auto bannerContainer'>
            <label className='bannerTitle' ></label>
            <a href={banner.link} target='_blank' rel='noreferrer' className='bannerDescription' >
                <img src={banner.image} alt={banner.title} title={banner.title} />
            </a>
        </div>
    )
}

export default Banner