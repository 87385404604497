import { useEffect } from 'react';
import { BACKEND_API } from '../config/backend.config';
import { useSelector } from 'react-redux';
import { sopurcesSliceActions } from '../store/features/sourcesSlice';
import { RootState } from '../store/store';
import { useAppDispatch } from '../store/hooks';

export interface Iitem {
  name: string;
  type: string;
  path: string;
}

function useLists() {
  const dispatch = useAppDispatch();
  const list: Iitem[] = useSelector((state: RootState) => state.sources.list);

  const { setList, setSources } = sopurcesSliceActions;
  useEffect(() => {
    fetch('https://api.usd.uy/list')
      .then((response) => response.json())
      .then((data) => {
        dispatch(setList(data?.message?.source))
        const promises = loadAllSources(data?.message?.source)
        Promise.all(promises).then((values) => {
          dispatch(setSources(values))
        })
      });
  }, []);

  return { list };
}

const loadAllSources = (sources: Iitem[]) => {
  const promises = sources.map((source) => {
    return fetch(`${BACKEND_API}/${source.path}`)
      .then((response) => response.json())
      .then((data) => {
        return data?.message[0];
      });
  });

  return promises
}





export { useLists };
