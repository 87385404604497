export interface SourceObj {
  source: string;
  url: string;
  currency: string;
  buy: number;
  sell: number;
  mediaLink: number;
  timestamp: string;
}

export interface GeoLocation {
  latitude: number;
  longitude: number;
}

export interface BrowserInfo {
  userAgent: string;
  appName: string;
  appVersion: string;
  platform: string;
  language: string;
}

export enum Operation {
  BUY = 'buy',
  SELL = 'sell',
}

export interface MyQuote {
  amount: number;
  option1: string;
  option2: string;
  source: string;
  sourceObj: SourceObj;
  browserInfo: BrowserInfo;
  geolocation?: GeoLocation | null;
  operation: Operation;
  total: number;
  currentQuote: number;
  created_at: string;
}
