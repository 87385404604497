import React from 'react';
import { useAuth } from '../hooks/Auth';
import useProviders from '../hooks/useProviders';
import { ReactComponent as GoogleSVG } from '../assets/google.svg';
import { ReactComponent as FacebookSVG } from '../assets/facebook.svg';
import { ReactComponent as AppleSVG } from '../assets/apple.svg';

const Login: React.FC = () => {
  const { signIn } = useAuth();
  const [useGoogleProvider, useFacebookProvider, useAppleProvider] =
    useProviders();
  // Funciones para iniciar sesión con diferentes proveedores
  const loginWithGoogle = () => {
    signIn({ provider: 'google' });
  };

  const loginWithFacebook = () => {
    signIn({ provider: 'facebook' });
  };

  const loginWithApple = () => {
    signIn({ provider: 'apple' });
  };

  return (
    <div className="bg-gray-200 flex items-center justify-center h-screen">
      <div className="bg-white p-8 rounded shadow-md max-w-sm w-full space-y-4 p-16">
        <img className="mx-auto" src="/image0.png" alt="usd.uy.logo" />
        <h2 className="text-2xl font-bold text-center">Continuar con</h2>
        <div className="space-y-4 text-white mb-5">
          {/* Botones para iniciar sesión con Google, Facebook y Apple */}
          {useGoogleProvider() && (
            <button
              onClick={loginWithGoogle}
              className="bg-red-600 py-2 px-4 rounded w-full text-left"
            >
              <GoogleSVG
                className="w-7 h-7 inline float-left ml-[30%] mr-5"
                fill="white"
                strokeWidth={'0.8'}
              />
              Google
            </button>
          )}
          {useFacebookProvider() && (
            <button
              onClick={loginWithFacebook}
              className="bg-blue-facebook py-2 px-4 rounded w-full text-left"
            >
              <FacebookSVG
                className="w-7 h-7 inline float-left ml-[30%] mr-5"
                fill="white"
                strokeWidth={'0.8'}
              />
              Facebook
            </button>
          )}
          {useAppleProvider() && (
            <button
              onClick={loginWithApple}
              className="bg-black py-2 px-4 rounded w-full text-left"
            >
              <AppleSVG
                className="w-7 h-7 float-left ml-[30%] mr-5"
                fill="white"
                strokeWidth={'0.8'}
              />
              <span>Apple</span>
            </button>
          )}
        </div>
        <div>
          <a href="/" className="text-sky mt-5">
            Continuar como invitado
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
