import { createSlice } from "@reduxjs/toolkit"

export interface ISource {
  bug: number,
  currency: string,
  sell: number,
  source: string,
  timestamp: string,
  url: string
}

const initialState = {
    list: [],
    sources: [],
} as {
  list: any[],
  sources: ISource[]
}

export const sourcesReducer = createSlice({
  name: 'sources',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setList: (state, action) => {
      state.list = action.payload
    },
    setSources: (state, action) => {
      state.sources = action.payload
    }, 
    },
});

export const sopurcesSliceActions = sourcesReducer.actions

export default sourcesReducer.reducer;




