
import { Session, SignInWithOAuthCredentials, User } from '@supabase/supabase-js';
import { createContext, useContext, useEffect, useState } from 'react';
import { supabase } from '../supabase';


const AuthContext = createContext<any>({ session: null, user: null, signOut: () => { } });

export const AuthProvider = ({ children }: any) => {

    const [user, setUser] = useState<User>();
    const [session, setSession] = useState<Session | null>();
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        const setData = async () => {
            const { data: { session }, error } = await supabase.auth.getSession();
            if (error) throw error;
            setSession(session);
            setUser(session?.user);
            setLoading(false);
        }

        const { data: listener } = supabase.auth.onAuthStateChange(async (_event, session) => {
            setSession(session);
            setUser(session?.user);
            setLoading(false);
        });

        setData();

        return () => {
            listener?.subscription.unsubscribe();
        }
    }, []);


    const value = {
        session,
        isSignedIn: !!session,
        user,
        signOut: () => supabase.auth.signOut(),
        signIn: (options: SignInWithOAuthCredentials) => supabase.auth.signInWithOAuth(options),
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )

}

export const useAuth = () => {
    return useContext(AuthContext);
} 