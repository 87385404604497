import ReactDOM from 'react-dom';
import App from './App.tsx';
import './index.css';
import { Provider } from 'react-redux';
import { store } from './store/store.ts';
import React from 'react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { inject } from '@vercel/analytics';
 
inject();
ReactDOM.render(
    <React.StrictMode>
    <Provider store={store}>
        <App />
        <SpeedInsights />
    </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

